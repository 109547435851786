import React from 'react';

const HeaderMint = () => {
  return (
    <div className="text-center">
      <h1 className="flex items-center justify-center sm:mb-4 space-x-2 font-bold text-lg leading-none">
        <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='block'>
          <g clipPath="url(#clip0_1462_2319)">
            <path d="M14.3996 8.0001C14.3996 11.5347 11.5342 14.4001 7.99961 14.4001C4.46499 14.4001 1.59961 11.5347 1.59961 8.0001C1.59961 4.46548 4.46499 1.6001 7.99961 1.6001C11.5342 1.6001 14.3996 4.46548 14.3996 8.0001Z" fill="#C2E900" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 4L0 0L4 0V0.4L0.4 0.4L0.4 4H0ZM16 4V0L12 0V0.4L15.6 0.4V4H16ZM16 12H15.6V15.6H12V16H16V12ZM4 16V15.6H0.4L0.4 12H0L0 16H4Z" fill="#959595" />
          </g>
          <defs>
            <clipPath id="clip0_1462_2319">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <span className=''>SafeSoul</span>
      </h1>
    </div>
  );
};

export default HeaderMint;