import React from 'react';

import HeaderMint from './Header';

// import gotToErrorPage from '../helpers/goToErrorPage';

const MintLayout = ({ children }) => {
  // useEffect(() => {
  //   if (!error) return;

  //   gotToErrorPage();
  // }, [error]);

  return (
    <div className="bg-[#F3F3F4] pt-7 pb-5 sm:pt-32 sm:pb-14">
      <div className="max-w-lg mx-auto px-2 md:px-0">
        <HeaderMint />
        {children}
      </div>
    </div>
  );
};

export default MintLayout;