import React, { useEffect, useCallback, useState } from 'react';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useAccount, useSignMessage, useNetwork } from 'wagmi';

import { IS_PRODUCTION } from '@/constants/settings';

import MintLayout from '@/sections/mint/MintLayout';

import DelegateCashLogo from '@/images/icons/delegatecash-logo.png';

const extensionId = process.env.GATSBY_EXTENSION_ID ?? null;
let messageKey = null;

const WalletConnect = () => {
  const { openConnectModal } = useConnectModal();

  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();

  const [signMessageString, setSignMessageString] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [isErrorState, setIsErrorState] = useState(false);

  const { data: signature, isSuccess, isError, signMessage } = useSignMessage({
    message: signMessageString,
  });

  useEffect(() => {
    signMessageString && signMessage()
  }, [signMessageString]);

  const handleRequestSignature = useCallback(async (signature) => {
    if (!signMessageString || !signature) return;

    try {
      setInfoMessage(`<span>Check signature</span>`);

      window.postMessage({
        type: 'SEND_SIGNATURE',
        extensionId,
        message: signMessageString,
        signature
      }, "*");

    } catch (e) {
      console.log(e);

      setIsErrorState(true);

      return Promise.reject(e);
    }
  }, [signMessageString, signature, signMessage, setIsErrorState]);

  useEffect(() => {
    if (isSuccess, signature) handleRequestSignature(signature);
  }, [isSuccess, signature, handleRequestSignature]);

  // useEffect(() => {
  //   if (!isError || !signMessageString) return;

  //   setIsErrorState(true);
  // }, [isError, signMessageString]);

  const isMesageKeyWrong = (data) => {
    if (messageKey !== data.messageKey) {
      setIsErrorState(true);

      console.log('Wrong message key');

      return true;
    }

    return false;
  };

  const handleContentScriptMessages = useCallback((e) => {
    const { data = null } = e;

    if (!data) return;

    if (IS_PRODUCTION) {
      if (data.extensionId !== extensionId) return;
    }

    if (!data.extensionId || !data?.messageKey) return;

    if (data.type === 'SAFESOUL_MESSAGE_KEY') {
      if (messageKey) return;

      messageKey = data.messageKey;
    }

    if (data.type === 'SIGNIN_MESSAGE' && data.message) {
      if (isMesageKeyWrong(data)) return;

      setInfoMessage(`<span>Waiting signature</span>`);

      setSignMessageString(data.message);
    }

    if (data.type === 'SUCCESS_SIGNIN') {
      if (isMesageKeyWrong(data)) return;

      setInfoMessage(`<span>Success</span>`);

      setTimeout(() => {
        window.close();

        setInfoMessage(`<span>Now close window</span>`);
      }, 2000);
    }

    if (data.type === 'ERROR_MESSAGE') {
      setIsErrorState(true);
    }
  }, [setInfoMessage, setSignMessageString]);

  useEffect(() => {
    if (isConnected && address) {
      setInfoMessage('Connected');

      setTimeout(() => {
        window.postMessage({
          type: 'GET_SIGNIN_WALLET',
          extensionId,
          wallet: address,
          chainId: chain.id,
        }, "*");
      }, 1000);
    } else {
      setInfoMessage('Waiting connection');
    }
  }, [isConnected, address, chain?.id]);

  const handleClosePage = useCallback(() => {
    window.close();
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleContentScriptMessages);

    if (!messageKey) {
      window.postMessage({
        type: 'PAGE_LOADED',
        extensionId,
      }, "*");
    }

    return () => {
      window.removeEventListener('message', handleContentScriptMessages);
    };
  }, [handleContentScriptMessages]);

  useEffect(() => {
    // Open rainbow connect modal on load page
    openConnectModal && openConnectModal();
  }, [openConnectModal]);

  return (
    <MintLayout>
      <div className="min-h-screen">

        {
          !isErrorState ?
            <>
              <div className="bg-white rounded-2xl p-4 sm:p-6 mt-2 min-h-[80px]">
                <p id="info-message" dangerouslySetInnerHTML={{ __html: infoMessage }} className="text-2xl text-black font-bold text-center"></p>
              </div>
              <div className="mt-2 p-1 text-xs flex justify-center items-center gap-1">
                <img src={DelegateCashLogo} alt="Wallet connect" className="h-4 w-4" />
                We support Delegate solutions
              </div>
            </>
            :
            <div className="bg-white rounded-2xl p-4 sm:p-6 mt-2">
              <p className="mb-2 text-2xl text-black font-bold text-center">Something went wrong</p>
              <p className="mb-6 text-black/60 font-medium text-center">It looks like a system error, try to close this window and connect the wallet again</p>

              <button type="button" className='flex items-center justify-center w-full py-5 px-10 text-white font-bold leading-none transition bg-[#212121] hover:bg-[#212121]/80 rounded-lg' onClick={handleClosePage}>Close</button>
            </div>
        }
      </div>
    </MintLayout>
  )
};

export default WalletConnect;

export const Head = () => {
  return <>
    <title>SafeSoul | Connect wallet</title>
    <meta name="description" content="Free browser extension integrated into Twitter alerts you to scams and suspicious activity in your feed. Immediate safety check for any open page: project on OS, scammed website etc." />
    <meta property="og:title" content="SafeSoul" />
    <meta property="twitter:title" content="SafeSoul" />
    <meta property="og:site_name" content="SafeSoul" />
    <meta property="og:url" content="https://safesoul.club" />
    <meta property="og:type" content="website" />
    <meta property="og:description" content="Free browser extension integrated into Twitter alerts you to scams and suspicious activity in your feed. Immediate safety check for any open page: project on OS, scammed website etc." />
    <meta property="twitter:description" content="Free browser extension integrated into Twitter alerts you to scams and suspicious activity in your feed. Immediate safety check for any open page: project on OS, scammed website etc." />
    <meta property="og:image" content="https://safesoul.club/assets/share.jpg" />
    <meta property="twitter:image" content="https://safesoul.club/assets/share.jpg" />
    <meta property="og:site_name" content="SafeSoul" />
    <meta property="og:locale" content="en_US" />
    <meta name="twitter:card" content="summary_large_image" />
  </>
}